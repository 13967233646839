/* eslint-disable */
import { lazy } from 'react';
// import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const dashboard = lazy(() => import('views/dashboard/Dashboard'));
const materials = {
  list: lazy(() => import('views/materials/MaterialList')),
  add: lazy(() => import('views/materials/AddMaterial')),
};
const event_setting = {
  list: lazy(() => import('views/event-setting/EventSettingList')),
  catalog_list: lazy(() => import('views/event-setting/CatalogTermList')),
};
const event_report = {
  eventList: lazy(() => import('views/event-report/EventReportList')),
  lotList: lazy(() => import('views/event-report/ResultLotList')),
  lotHistoryList: lazy(() => import('views/event-report/LotHistoryList')),
  approvalList: lazy(() => import('views/event-report/EventReportList')),
};
const events = {
 
  list: lazy(() => import('views/events/EventList')),
  add: lazy(() => import('views/events/AddEvent')),
};


const emp = {
  emp_list: lazy(() => import('views/employee/EmployeeList')),
  role_list: lazy(() => import('views/employee/RoleList'))
};
const auction = {
  upcomingEventList: lazy(() => import('views/auction/list/UpcomingEventList')),
  pastEventList: lazy(() => import('views/auction/list/PastEventList')),
  pastLotList: lazy(() => import('views/auction/list/PastLotList')),
  eventList: lazy(() => import('views/auction/list/LiveEventList')),
  monitoringList: lazy(() => import('views/auction/list/MonitoringList')),
  clientUserList: lazy(() => import('views/auction/list/ClientUserList'))
};
const account = {
  editprofile: lazy(() => import('views/account/EditProfile.js')),
  editcompanydetails: lazy(() => import('views/account/EditCompanyDetails.js')),
  editbankdetails: lazy(() => import('views/account/EditBankDetails.js')),
  changePassword: lazy(() => import('views/account/ChangePassword.js'))
  
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/login`,
    },
   
    {
      path: `${appRoot}/dashboard`,
      component: dashboard,
      label: 'menu.dashboard',
      icon: 'shop',
      protected: true
    },
    {
      path: `${appRoot}/auction`,
      exact: true,
      redirect: true,
      to: `${appRoot}/auction/live`,
      label: 'menu.schedule',
      icon: 'calendar',
      protected: true,

      subs: [
        { path: '/live-events', label: 'menu.live', component: auction.eventList },
        { path: '/upcoming-events', label: 'menu.upcoming', component: auction.upcomingEventList },
        { path: '/past-events', label: 'menu.past', component: auction.pastEventList },
        { path: '/past-lot-list/:eventId', component: auction.pastLotList },
        { path: '/monitoring-list', component: auction.monitoringList },
        { path: '/client-users-list', component: auction.clientUserList }
      ],
    },
    {
      path: `${appRoot}/account`,
      exact: true,
      redirect: true,
      to: `${appRoot}/account/edit-profile`,
      label: 'menu.account',
      icon: 'screen',
      protected: true,
      subs: [
        { path: '/edit-profile', label: 'menu.editprofile', component: account.editprofile },
        { path: '/edit-bank-details', label: 'menu.editbankdetails', component: account.editbankdetails },
        { path: '/edit-company-details', label: 'menu.editcompanydetails', component: account.editcompanydetails },
        { path: '/change-password', label: 'menu.changepassword', component: account.changePassword },
      ],
    }
    // {
    //   path: `${appRoot}/event-reports`,
    //   label: 'menu.event-report',
    //   icon: 'lecture',
    //   exact: true,
    //   redirect: true,
    //   protected: true,
    //   to: `${appRoot}/event-reports/list`,
    //   subs: [
    //     { path: '/list', label: 'menu.resultList', component: event_report.eventList },
    //     { path: '/lot-list/:id', component: event_report.lotList },
    //     { path: '/lot-history-list/:id', component: event_report.lotHistoryList },
    //     { path: '/approval-list', label: 'menu.approvalList', component: event_report.approvalList },
    //   ],
    // },
    // {
    //   path: `${appRoot}/materials/add-material`,
    //   component: materials.add,
    //   protected: true
    // },
    // {
    //   path: `${appRoot}/materials`,
    //   label: 'menu.material',
    //   icon: 'quiz',
    //   exact: true,
    //   redirect: true,
    //   protected: true,
    //   to: `${appRoot}/material/list`,
    //   subs: [
    //     { path: '/list', label: 'menu.list', component: materials.list },
    //   ],
    // },
    // {
    //   path: `${appRoot}/event-setting`,
    //   label: 'menu.event-setting',
    //   icon: 'settings-1',
    //   exact: true,
    //   redirect: true,
    //   protected: true,
    //   to: `${appRoot}/event-setting/list`,
    //   subs: [
    //     { path: '/list', label: 'menu.event-engine-setting', component: event_setting.list },
    //     { path: '/catalog-term-list', label: 'menu.event-catalog', component:event_setting.catalog_list }
    //   ],
    // },
    
    // {
    //   path: `${appRoot}/events`,
    //   label: 'menu.events',
    //   icon: 'destination',
    //   exact: true,
    //   redirect: true,
    //   protected: true,
    //   to: `${appRoot}/events/list`,
    //   subs: [
        
    //     { path: '/list', label: 'menu.list', component: events.list },
    //     { path: '/add-event',  component: events.add },
        
       
    //   ],
    // },
    
    // {
    //   path: `${appRoot}/emp`,
    //   label: 'menu.emp-management',
    //   icon: 'user',
    //   exact: true,
    //   redirect: true,
    //   protected: true,
    //   to: `${appRoot}/emp/emp-list`,
    //   subs: [
    //     { path: '/roles-list', label: 'menu.roles', component: emp.emp_list },
    //     { path: '/emp-list', label: 'menu.emp', component: emp.role_list }
    //   ],
    // },
    // {
    //   path: `${appRoot}/bidder`,
    //   label: 'menu.bidder-management',
    //   icon: 'online-class',
    //   exact: true,
    //   redirect: true,
    //   protected: true,
    //   to: `${appRoot}/bidder/client-user-list`,
    //   subs: [
    //     { path: '/client-user-list', label: 'menu.client-user', component: emp.emp_list },
    //     { path: '/selected-user-list', label: 'menu.selected-user', component: emp.role_list }
    //   ],
    // },
    // {
    //   path: `${appRoot}/follow-up`,
    //   label: 'menu.followup-management',
    //   icon: 'layout-5',
    //   exact: true,
    //   redirect: true,
    //   protected: true,
    //   to: `${appRoot}/follow-up/live-auction-list`,
    //   subs: [
    //     { path: '/live-auction-list', label: 'menu.live-auction', component: emp.emp_list },
    //     { path: '/pre-auction-list', label: 'menu.pre-auction', component: emp.role_list },
    //     { path: '/post-auction-list', label: 'menu.post-auction', component: emp.role_list },
    //   ],
    // },
    
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
